import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { parse } from 'query-string';
import styled from 'styled-components';
import { sanitize } from 'dompurify';
import { useLayoutQueries } from '../utils/queries';
import storage from '../utils/storage';
import sendRequest from '../utils/forms/API/sendRequest';

export const RmpContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  padding: 20px;
  max-width: 960px;
  margin: 0 auto;

  h1 {
    font-size: 37px;
    font-weight: normal;
    color: #0257a2;
  }
  h3 {
    font-size: 22px;
    font-weight: normal;
    color: #0257a2;
  }
  #privacyModalOpen {
    cursor: pointer;
  }
`;

const RmpDns = () => {
  const [dns, setDNS] = useState('');
  const { thankyouRmpDns } = useLayoutQueries();
  useEffect(() => {
    const { stage, dev } = parse(window.location.search);
    let envToggle = '';
    if (stage === '1' || dev === '1') {
      envToggle = '-default';
    }

    const url = `https://external${envToggle}.printfinger.tech:7105/api/CheckRules`;
    const getStorage = storage('local', 'get', 'formData');
    const dnsContainer = document.getElementById('dns-container');

    if (dnsContainer) {
      dnsContainer.addEventListener('click', e => {
        e.preventDefault();
        const { id } = e.target;
        if (id === 'privacyModalOpen') {
          navigate('/rmp-privacy.html');
        }
      });
    }

    if (getStorage) {
      const formData = JSON.parse(getStorage);
      const { request_id, offer_id, affiliate_id, site_name } = formData;

      const requestData = {
        activity: 'Disclosure',
        RequestId: request_id || '',
        OfferId: offer_id || '',
        SiteName: site_name || window.location.hostname,
        AffiliateId: affiliate_id || '',
        AdvertiserName: 'RMP (SuCo Cake)',
      };

      const successFunction = response => {
        try {
          if (!response || !response.Payload) {
            setDNS(thankyouRmpDns.childPlainText.content);
          } else {
            const disclosureParse = JSON.parse(response.Payload);

            // Turn response into object
            const disclosureObj = {};
            disclosureParse.forEach(item => {
              const key = Object.keys(item);
              disclosureObj[key] = item[key];
            });

            storage(
              'session',
              'set',
              'disclosure_name',
              disclosureObj.DisclosureName
            );

            storage(
              'session',
              'set',
              'AffiliateAssociation',
              disclosureObj.AffiliateAssociation
            );

            setDNS(sanitize(disclosureObj.DoNotSellLink));
          }
        } catch (error) {
          console.log(error, 'No DNS found');
          setDNS(thankyouRmpDns.childPlainText.content);
        }
      };

      sendRequest(requestData, url, successFunction, 'POST');
    } else {
      setDNS(thankyouRmpDns.childPlainText.content);
    }
  }, [thankyouRmpDns.childPlainText.content]);

  return (
    <RmpContainer
      id="dns-container"
      dangerouslySetInnerHTML={{
        __html: dns,
      }}
    />
  );
};

export default RmpDns;
